/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import ReactGA from 'react-ga';
import Slider from 'react-slick';
import toast from 'react-hot-toast';
import {
  updateUserHandler,
  opsFeedHandler,
  customerFeedHandler,
  nprOpsFeedHandler,
  nprCustomerFeedHandler
} from '../service';
import {
  countryTimeMapper,
  order as statusCodeBucket
} from '../utils/entity_mapping';
import { strings } from '../utils/localizedStrings';
import { openUrl } from '../utils/helper';
// import { SUCCESS, statusMapper } from './Payment';

let activeSlide = 0;

const customEddColors = [
  'birkentock',
  '82e',
  'bookscape',
  'herbalife-test',
  'herbalife-id',
  'herbalife-fr'
];

class MetaCard extends Component {
  constructor(props) {
    super(props);
    this.gaEvents = this.gaEvents.bind(this);
    this.shareUrl = this.shareUrl.bind(this);
    this.openShare = this.openShare.bind(this);
    this.state = {
      activeSlide: 0,
      showDetails:
        ['damensch', 'gynoveda'].includes(this.props.user.subdomain) &&
        this.props.user.device === 'mobile'
          ? true
          : false
    };
  }

  goForward(route) {
    const { tracking, customerFeed, user } = this.props;
    const { routePrefix } = user;
    const { search, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);
    const security_key =
      searchParams.get('security_key') || searchParams.get('key');
    if (tracking.config.invalidSecurityCheck) {
      return null;
    }
    if (route === 'issue' && tracking.config.isRVP) {
      this.props
        .fetchNprOpsFeedHandler(tracking.trackingId, security_key)
        .then(res => {
          if (res.meta && res.meta.success) {
            this.props
              .fetchNprCustomerFeedHandler(tracking.trackingId, security_key)
              .then(res => {
                if (res.meta && res.meta.success && customerFeed) {
                  this.context.router.history.push(
                    `${routePrefix ? `/${routePrefix}` : ''}/reverse/issue${this
                      .context.router.history.location.search +
                      this.context.router.history.location.hash}`
                  );
                }
              });
          }
        });
    } else if (route === 'issue') {
      this.props
        .fetchOpsFeedHandler(tracking.trackingId, tracking.config.user)
        .then(res => {
          if (res.meta && res.meta.success) {
            this.props
              .fetchCustomerFeedHandler(
                tracking,
                tracking.customer,
                security_key
              )
              .then(res => {
                if (res.meta && res.meta.success && customerFeed) {
                  this.context.router.history.push(
                    `${routePrefix ? `/${routePrefix}` : ''}/issue${this.context
                      .router.history.location.search +
                      this.context.router.history.location.hash}`
                  );
                }
              });
          }
        });
    } else {
      this.context.router.history.push(
        `${routePrefix ? `/${routePrefix}` : ''}/${route}` +
          this.context.router.history.location.search +
          this.context.router.history.location.hash
      );
    }
  }
  gaEvents(e, eventName, label, action) {
    if (action) {
      ReactGA.event({
        category: eventName,
        label: label,
        action: action
      });
    }
  }

  shareUrl(name) {
    const { tracking, user, page } = this.props;

    let hostUrl = '';
    // there is a delay in getting and setting the trackingLink in the tracking object. So, need to check if if is available and is a parseable URL or not first.
    if (URL.canParse(tracking?.trackingLink)) {
      const url = new URL(tracking.trackingLink);
      const urlHostname = url.hostname;
      hostUrl = urlHostname ?? user.userHostname + page.url;
    }
    const enterpriseProfile = tracking.theme.meta_data;
    switch (name) {
      case 'whatsapp':
        return `whatsapp://send?text=Track my order @%20${hostUrl}?ref=social_wa_clickpost`;
      case 'facebook':
        return `https://www.facebook.com/dialog/share?app_id=1932988240339501&display=popup&href=${hostUrl}&redirect_uri=${encodeURIComponent(
          hostUrl
        )}&hashtag=${enterpriseProfile.hashtag}&quote=Track my order`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?original_referer=${hostUrl}&ref=social_tw_clickpost&text=Track my order&url=${hostUrl}&ref=social_tw_clickpost&via=${enterpriseProfile.twitterHandle}`;
      default:
    }
  }

  openShare() {
    let { user } = this.props;
    user = Object.assign(user, { shareOn: true });
    this.props.updateUserHandler(user);
  }

  linkButton = e => {
    const { user, tracking } = this.props;
    e.preventDefault();
    let banners = [];
    if (tracking.theme.meta_data && tracking.theme.meta_data.banners) {
      banners = tracking.theme.meta_data.banners.filter(
        item => item.type === 'top'
      );
    }
    const { redirect_url, title } = banners[activeSlide];
    ReactGA.event({
      category: 'Tile',
      action: redirect_url,
      label: title
    });
    if (user.webview) {
      window.location = redirect_url;
    } else {
      openUrl(redirect_url, '_blank');
      // window.open(redirect_url, '_blank');
    }
  };

  copyToClipboard = text => {
    if (navigator?.clipboard) {
      navigator?.clipboard
        ?.writeText(text)
        .then(() => {
          toast.success(`${text} copied to clipboard`);
        })
        .catch(() => toast.error('Copy to clipboard not available'));
    } else if (document) {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        toast.error('Copy to clipboard not available');
      } finally {
        toast.success(`${text} copied to clipboard`);
        textArea.remove();
      }
    } else {
      toast.error('Copy to clipboard not available');
    }
  };

  renderReattemptInstruction = () => {
    const { customerFeed, user } = this.props;
    const { lang } = user;
    switch (customerFeed.issue_id) {
      case 1:
        return (
          <div className="row row-flex">
            <div className="col-md-7 col-xs-24 order-info">
              <span className="return-label">
                {strings[lang].preferredDate}
              </span>
              <span className="return-text">
                {moment(customerFeed?.preferred_date?.slice(0, 16)).format(
                  'DD-MM-YYYY'
                )}
              </span>
            </div>
            <div className="col-md-17 col-xs-24 overflow order-info">
              <span className="return-label">{strings[lang].address}</span>
              <span className="return-text">{customerFeed.address}</span>
            </div>
          </div>
        );
      case 2:
      case 3:
      case 5:
        return (
          <div className="row row-flex">
            <div className="col-md-7 col-xs-24 order-info">
              <span className="return-label">
                {strings[lang].preferredDate}
              </span>
              <span className="return-text">
                {moment(customerFeed?.preferred_date?.slice(0, 16)).format(
                  'DD-MM-YYYY'
                )}
              </span>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="row row-flex">
            <div className="col-md-7 col-xs-24 order-info">
              <span className="return-label">
                {strings[lang].preferredDate}
              </span>
              <span className="return-text">
                {moment(customerFeed?.preferred_date?.slice(0, 16)).format(
                  'DD-MM-YYYY'
                )}
              </span>
            </div>
            <div className="col-md-17 col-xs-24 overflow order-info">
              <span className="return-text">
                Our courier partner will attempt to contact you at{' '}
                <strong>{customerFeed.phone_number}</strong>
              </span>
            </div>
          </div>
        );
      case 6:
        if (!customerFeed.is_rejected) {
          return (
            <div className="row row-flex">
              <div className="col-md-7 col-xs-24 order-info">
                <span className="return-label">
                  {strings[lang].preferredDate}
                </span>
                <span className="return-text">
                  {moment(customerFeed?.preferred_date?.slice(0, 16)).format(
                    'DD-MM-YYYY'
                  )}
                </span>
              </div>
            </div>
          );
        }
        return (
          <div className="row row-flex">
            <div className="col-md-17 col-xs-24 order-info">
              <span className="return-text">
                Delivery cancelled. For further details of this order please
                visit My Order page.
              </span>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  render() {
    const { tracking, user, handleImageLoad, customerFeed } = this.props;
    const { udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';
    let showReattemptInstructions =
      customerFeed.issue_id !== null && tracking.isNewNdr === false
        ? customerFeed.issue_id
        : null;
    if (customerFeed.issue_id === 1 && udfKeys?.addressIssueBFSIMessage) {
      showReattemptInstructions = false;
    }
    const { lang } = user;
    const { config } = tracking.theme;
    const hasLessMeta =
      !tracking.config.orderDate &&
      !tracking.predict.edd &&
      !tracking.predict.message &&
      !(tracking.config.isUpdateContact && !tracking.config.isRVP) &&
      !tracking.theme.meta_data.display_rider_info;
    let banners = [];
    if (tracking.theme.meta_data && tracking.theme.meta_data.banners) {
      banners = tracking.theme.meta_data.banners.filter(
        item => item.type === 'top'
      );
    }
    const settings = {
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      margin: 0,
      centerMode: true,
      centerPadding: '0px',
      arrows: true,
      slidesToScroll: 1,
      swipeToSlide: false,
      dots: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      verticalSwiping: false,
      draggable: false,
      swipe: false,
      beforeChange: (current, next) => {
        activeSlide = next;
      }
    };
    const slides = banners.map((item, i) => {
      const herbalifeTitles = {
        'How to Hydrate Fast and What Liquids Count as Water Intake?':
          'Cara Menghidrasi dengan Cepat dan Cairan Apa Saja yang Dihitung sebagai Asupan Air',
        '10 Heart-Healthy Foods and Snacks to Add to Your Grocery List':
          '10 Makanan dan Camilan yang mendukung kesehatan jantung'
      };
      const isHerbalifeId =
        (user.subdomain === 'herbalife-id' ||
          user.subdomain === 'herbalife-test') &&
        lang === 'id';
      const title =
        isHerbalifeId && herbalifeTitles[item.title]
          ? herbalifeTitles[item.title]
          : item.title;
      return (
        <div className="banner cur-pointer" key={i}>
          <img
            src={item.url}
            title={title}
            alt={isHerbalifeId ? title : item.alt}
            onClick={e => this.linkButton(e, item)}
            onLoad={handleImageLoad}
            style={{ maxHeight: 270 }}
          />
        </div>
      );
    });
    const mobileFirstScreen = () => {
      return (
        <div
          className={`row mrg-0 text-left pd-top-10 ${
            hasLessMeta ? '' : 'xs-pd-btm-10'
          }`}
        >
          <div
            className={`col-xs-24 order-info ${
              tracking.orderId
                ? 'col-md-7'
                : `${user.subdomain === 'bewakoof' ? 'col-md-10' : 'col-md-8'}`
            }`}
          >
            {tracking?.predict?.edd ? (
              <>
                <span className="label">{strings[lang].expectedDelivery}</span>
                <span className={`text`}>
                  {moment(tracking.predict.edd).format('Do MMM YY')}
                </span>
              </>
            ) : (
              <>
                <span className={`label ${udfKeys.titleTextClassName}`}>
                  {strings[lang].lastUpdate}
                </span>
                <span className={`text edd-color`}>
                  <strong
                    style={{
                      color:
                        customEddColors.indexOf(user.subdomain) > -1
                          ? ''
                          : tracking.order.timelineColor
                    }}
                  >
                    {tracking.latestStatus.text === 'Order Placed'
                      ? ''
                      : tracking.latestStatus.text}
                  </strong>{' '}
                  on{' '}
                  {moment(tracking.latestStatus.timestamp.slice(0, 16)).format(
                    'Do MMM, hh:mma'
                  )}
                </span>
              </>
            )}

            <span
              className="icon-navigate_next cm-right arrow-icon down cur-pointer"
              onClick={() => this.setState({ showDetails: true })}
              tabIndex="0"
              role="button"
            />
          </div>
          <div className="col-md-4 col-xs-24 order-info">
            <span className={`label ${udfKeys.titleTextClassName}`}>
              {strings[lang].trackingId}
            </span>
            <span className={`text`}>{tracking.waybill}</span>
          </div>
        </div>
      );
    };
    // adding orderType in both first and second row based on hasLessMeta condition
    const orderTypeJSX = (
      <div className={`col-md-4 col-xs-24 order-info bd-left-1`}>
        <span className={`label ${udfKeys.titleTextClassName}`}>
          {strings[lang].orderType}
        </span>
        <span className={`text`}>
          {/* adding prepaid order type when user redirected from gateway after successfull payment for cod-to-prepaid conversion */}
          {// this.props.paymentInfo?.paymentLink &&
          // !this.props.paymentInfo?.seen &&
          // statusMapper(
          //   this.props.paymentInfo?.status,
          //   Number(this.props.page.paymentStatus)
          // ) === SUCCESS
          //   ? 'PREPAID'
          //   :
          user.subdomain === 'herbalife-fr' &&
          tracking.config.orderType === 'PREPAID'
            ? orderTypeInfo[lang ?? 'en'][tracking.config.orderType]
            : tracking.config.orderType}
        </span>
      </div>
    );

    const theSleepCompany = [
      'thesleepcompany',
      'thesleepcompany-test'
    ].includes(user.subdomain);

    const orderTypeInfo = {
      fr: {
        PREPAID: 'Payé'
      },
      en: {
        PREPAID: 'PREPAID'
      }
    };

    const topBanner = ({ classname }) => {
      return (
        <>
          {banners.length > 0 &&
          tracking.theme.meta_data &&
          (tracking.theme.meta_data.ads_enabled ||
            tracking.theme.meta_data.ads_enabled === undefined) ? (
            <div className="row">
              <div className={`"col-md-24 col-xs-24 ${classname}`}>
                <Slider {...settings}>{slides}</Slider>
              </div>
            </div>
          ) : null}
        </>
      );
    };

    const isRblBank = ['rblbank', 'rblbank-test'].includes(user.subdomain);
    return (
      <Fragment>
        {isRblBank && topBanner({ classname: 'mrg-btm-20 xs-mrg-btm-10' })}
        <div
          className={`card meta-card`}
          style={{ height: '100%', minHeight: 'unset' }}
        >
          {user.device === 'desktop' ||
          this.state.showDetails ||
          !this.props.productsEnabled ? (
            <>
              <div
                className={`row mrg-0 text-left pd-top-10 pd-btm-10 ${
                  hasLessMeta ? '' : 'xs-pd-btm-0'
                }`}
              >
                <div className={`col-md-4 col-xs-24 order-info bd-left-0`}>
                  <span className={`label ${udfKeys.titleTextClassName}`}>
                    {strings[lang].courier}{' '}
                  </span>
                  <span className={`text`}>
                    {user.subdomain === 'shypbuddy' &&
                    tracking.courier.name === 'Smartship'
                      ? 'Bluedart'
                      : tracking.courier.name}
                  </span>
                  {user.device === 'mobile' && this.props.productsEnabled && (
                    <span
                      className="icon-navigate_next cm-right arrow-icon up cur-pointer"
                      onClick={() => this.setState({ showDetails: false })}
                      tabIndex="0"
                      role="button"
                    />
                  )}
                </div>
                <div
                  className={` ${
                    user.subdomain === 'itsaugust-test'
                      ? 'col-md-6'
                      : 'col-md-4'
                  } col-xs-24 order-info`}
                >
                  <span className={`label ${udfKeys.titleTextClassName}`}>
                    {strings[lang].trackingId}
                  </span>
                  <span
                    data-tip={tracking.waybill}
                    className={`text truncate-length`}
                    data-tooltip-id="waybill-tooltip"
                  >
                    {tracking.waybill}
                  </span>
                  <span
                    role="presentation"
                    className="cursor-pointer mrg-left-5 align-top"
                    onClick={() => this.copyToClipboard(tracking.waybill)}
                  >
                    <img src={'/images/copy.svg'} alt="copy" />
                  </span>
                  <ReactTooltip id="waybill-tooltip" />
                </div>
                {tracking.orderId ? (
                  <div
                    className={`col-md-5 col-xs-24 order-info ${
                      ['rblbank', 'rblbank-test'].includes(user.subdomain)
                        ? 'hide'
                        : 'show'
                    }`}
                  >
                    <span className={`label ${udfKeys.titleTextClassName}`}>
                      {strings[lang].orderId}
                    </span>
                    <span className={`text`}>
                      {tracking.orderId != null ? tracking['orderId'] : 'NA'}
                    </span>
                  </div>
                ) : null}

                {tracking.config?.orderType &&
                hasLessMeta &&
                ![
                  'oriflame',
                  'oriflame-test',
                  'rblbank',
                  'rblbank-test'
                ].includes(user.subdomain)
                  ? orderTypeJSX
                  : null}
                <div
                  className={`col-xs-24 order-info ${
                    tracking.orderId
                      ? 'col-md-7'
                      : `${
                          user.subdomain === 'bewakoof'
                            ? 'col-md-10'
                            : 'col-md-8'
                        }`
                  }`}
                >
                  <span className={`label ${udfKeys.titleTextClassName}`}>
                    {strings[lang].lastUpdate}
                  </span>
                  <span className={`text edd-color`}>
                    <strong
                      style={{
                        color:
                          customEddColors.indexOf(user.subdomain) > -1
                            ? ''
                            : tracking.order.timelineColor
                      }}
                    >
                      {tracking.latestStatus.text === 'Order Placed'
                        ? ''
                        : tracking.latestStatus.text}
                    </strong>{' '}
                    {strings[lang].on}{' '}
                    {moment(
                      tracking.latestStatus.timestamp.slice(0, 16)
                    ).format('Do MMM, hh:mma')}
                  </span>
                </div>
                <div
                  className={`col-xs-24 order-info  hidden-xs text-right bd-left-0 ${
                    user.subdomain === 'bewakoof'
                      ? 'col-md-6'
                      : `${tracking.orderId ? 'col-md-4' : 'col-md-8'}`
                  }`}
                >
                  {!tracking.config.invalidSecurityCheck ? (
                    <Fragment>
                      <button
                        className={`secondary-btn hidden-xs ${
                          tracking.config.takeAction ? '' : 'hide'
                        }`}
                        onClick={e => this.goForward('issue', e)}
                        data-tip={strings[lang].takeActionTooltip}
                      >
                        <span className="icon-help" />{' '}
                        <span className="hidden-xs">
                          {strings[lang].takeAction}
                        </span>
                      </button>
                      <button
                        className={`secondary-btn mrg-left-10 ${
                          tracking.config.isUpdateContact &&
                          !tracking.config.isRVP
                            ? 'hidden-xs'
                            : 'hide'
                        }`}
                        onClick={e => this.goForward('update-contact', e)}
                        data-tip={strings[lang].updateContactTooltip}
                      >
                        <span className="hidden-xs">
                          {strings[lang].updateContact}
                        </span>
                      </button>
                    </Fragment>
                  ) : null}
                  {tracking.theme.meta_data &&
                  tracking.theme.meta_data.my_order_url &&
                  udfKeys?.enableMyOrderTracking ? (
                    <a
                      className={`secondary-btn mrg-left-10`}
                      href={tracking.theme.meta_data.my_order_url}
                      rel="noreferrer"
                      target="_blank"
                      data-tip={strings[lang].myOrdersTooltip}
                    >
                      {strings[lang].myOrders}
                    </a>
                  ) : null}
                </div>
              </div>
              <div
                className={`row mrg-0 text-left pd-top-10 pd-btm-10 xs-pd-top-0 xs-bd-0 
        ${hasLessMeta ? 'hide' : ' bd-top-1'}`}
              >
                {(user.device === 'desktop' || this.state.showDetails) &&
                tracking.config.orderDate ? (
                  <div className={`col-md-4 col-xs-24 order-info bd-left-0`}>
                    <span className={`label ${udfKeys.titleTextClassName}`}>
                      {strings[lang].orderPlacedOn}
                    </span>
                    <span className={`text`}>
                      {moment(tracking.config.orderDate.slice(0, 10)).format(
                        'Do MMM YY'
                      )}
                    </span>
                  </div>
                ) : null}
                {tracking.config?.orderType &&
                ![
                  'oriflame',
                  'oriflame-test',
                  'rblbank',
                  'rblbank-test'
                ].includes(user.subdomain)
                  ? orderTypeJSX
                  : null}
                {tracking.theme.meta_data.display_rider_info ? (
                  <div className={`col-md-6 col-xs-24 order-info`}>
                    <span className={`label ${udfKeys.titleTextClassName}`}>
                      {strings[lang].riderInfo}
                    </span>
                    <span className={`text`}>
                      {tracking.additional?.deliveryRider?.name ? (
                        <span className="bd-right-1 pd-right-5 mrg-right-5">
                          {tracking.additional?.deliveryRider?.name}
                        </span>
                      ) : null}
                      {tracking?.additional?.deliveryRider?.phoneNumber}
                      <span
                        role="presentation"
                        className="cursor-pointer mrg-left-5 hidden-xs"
                        onClick={() =>
                          this.copyToClipboard(
                            tracking?.additional?.deliveryRider?.phoneNumber
                          )
                        }
                      >
                        <img src={'/images/copy.svg'} alt="copy" />
                      </span>
                      <ReactTooltip id="rider-tooltip" className="hidden-xs" />
                      <a
                        className="rider call mrg-left-5 visible-xs-inline-flex"
                        href={`tel:${tracking.additional.deliveryRider.phoneNumber}`}
                      >
                        <span className="icon icon-phone" />
                      </a>
                    </span>
                  </div>
                ) : null}
                {showReattemptInstructions && (
                  <div className={`col-md-20 col-xs-24`}>
                    {user.device === 'mobile' && (
                      <hr className="mrg-top-10 mrg-btm-10" />
                    )}
                    <div className="row row-flex">
                      <div className="col-md-24 order-info">
                        <span className="mrg-right-20 fw-600">
                          {strings[lang].returnInstructions}
                        </span>
                        {/* </div>
                      <div className="col-md-19"> */}
                        <span
                          className={`return-label ${udfKeys.titleTextClassName}`}
                        >
                          {strings[lang].updatedOn}
                        </span>
                        <span className="return-text">
                          {moment
                            .utc(customerFeed.timestamp.slice(0, 16))
                            .utcOffset(countryTimeMapper(config.country_code))
                            .format('Do MMM, hh:mma')}
                        </span>
                      </div>
                    </div>
                    {this.renderReattemptInstruction()}
                  </div>
                )}

                {!showReattemptInstructions ? (
                  <>
                    {tracking?.predict?.edd ||
                    (tracking?.predictEDD?.expected_date_of_delivery &&
                      ['oriflame', 'oriflame-test'].includes(user.subdomain) &&
                      !tracking?.predictEDD?.is_delivered) ||
                    (tracking.theme.meta_data.shopify_edd &&
                      tracking.theme.meta_data.shopify_edd !== 'false' &&
                      tracking?.predict?.edd_range?.start &&
                      tracking?.predict?.edd_range?.end) ? (
                      <div
                        className={`${
                          theSleepCompany ? 'col-md-8' : 'col-md-4'
                        } col-xs-24 order-info`}
                      >
                        <span className={`label ${udfKeys.titleTextClassName}`}>
                          {strings[lang].expectedDelivery}
                        </span>
                        <span className={`text`}>
                          {
                            <span>
                              {/**
                               * If shopify_edd is enabled and it has date range then show date range else show computed EDD date.
                               */
                              tracking.theme.meta_data.shopify_edd &&
                              tracking.theme.meta_data.shopify_edd !==
                                'false' &&
                              tracking?.predict?.edd_range?.start &&
                              tracking?.predict?.edd_range?.end
                                ? `${moment(
                                    tracking.predict?.edd_range?.start
                                  ).format('Do MMM')} - ${moment(
                                    tracking.predict?.edd_range?.end
                                  ).format('Do MMM YY')}`
                                : moment(
                                    tracking.predict.edd ??
                                      tracking.predictEDD
                                        .expected_date_of_delivery
                                  ).format('Do MMM YY')}
                            </span>
                          }
                        </span>
                      </div>
                    ) : null}

                    {/**
                     * If shopify_edd is enabled and order is placed but not shipped then don't show EDD message.
                     * This is done because we are showing shopify_edd date range in expected delivery section
                     * and it doesn't match with the our computed EDD date and message.
                     */
                    !(
                      tracking.theme.meta_data.shopify_edd &&
                      tracking.theme.meta_data.shopify_edd !== 'false' &&
                      statusCodeBucket.orderPlacedButNotShipped.indexOf(
                        tracking.latestStatus.code
                      ) > -1
                    ) && tracking.predict.message ? (
                      <div
                        className={`col-xs-24 order-info ${
                          tracking.predict.edd ? 'col-md-11' : 'col-md-16'
                        } ${theSleepCompany && 'hide'}`}
                      >
                        <span
                          className={`text no-label edd-color xs-li-ht-20 li-ht-25`}
                          style={{
                            color:
                              customEddColors.indexOf(user.subdomain) > -1
                                ? ''
                                : tracking.predict.color
                          }}
                        >
                          {tracking.latestStatus.text === 'Order Placed' ||
                          (tracking.latestStatus.text == 'Ready To Ship' &&
                            udfKeys?.customPredictMessage)
                            ? udfKeys?.customPredictMessage
                            : tracking.predict.message}
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {(tracking.config.isUpdateContact && !tracking.config.isRVP) ||
                tracking.config.takeAction ||
                tracking.product ||
                (tracking.theme.meta_data &&
                  tracking.theme.meta_data.my_order_url &&
                  (user.subdomain === 'bewakoof' ||
                    user.subdomain === 'fashor')) ? (
                  <div className="col-md-4 col-xs-24 xs-mrg-top-5 pull-right text-right xs-text-left">
                    <button
                      className={`secondary-btn xs-secondary-btn mrg-right-10 ${
                        tracking.config.isUpdateContact &&
                        !tracking.config.isRVP
                          ? 'visible-xs-inline-block'
                          : 'hide'
                      }`}
                      onClick={e => this.goForward('update-contact', e)}
                      data-tip={strings[lang].updateContactTooltip}
                    >
                      <span>{strings[lang].updateContact}</span>
                    </button>
                    {!tracking.config.invalidSecurityCheck ? (
                      <button
                        className={`secondary-btn xs-secondary-btn mrg-right-10 ${
                          tracking.config.takeAction
                            ? 'visible-xs-inline-block'
                            : 'hide'
                        }`}
                        onClick={e => this.goForward('issue', e)}
                        data-tip={strings[lang].takeActionTooltip}
                      >
                        <span className="icon-help mrg-right-5" />
                        <span>{strings[lang].takeAction}</span>
                      </button>
                    ) : null}
                    {tracking.theme.meta_data &&
                    tracking.theme.meta_data.my_order_url &&
                    udfKeys?.enableMyOrderTracking ? (
                      <a
                        className={`secondary-btn visible-xs-inline-block`}
                        href={tracking.theme.meta_data.my_order_url}
                        rel="noreferrer"
                        target="_blank"
                        data-tip={strings[lang].myOrdersTooltip}
                      >
                        {strings[lang].myOrders}
                      </a>
                    ) : null}
                    {!showReattemptInstructions ? (
                      <div
                        className={`share ${
                          !tracking.product || udfKeys?.hideShareButton
                            ? 'hide'
                            : ''
                        } ${user.subdomain === 'boodmo' ? 'hide' : ''}`}
                        onClick={
                          user.device === 'mobile' ? this.openShare : null
                        }
                      >
                        {/* <div className="icon first">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              user.device === 'desktop'
                                ? null
                                : null
                            }
                          >
                            <span className="share-buton icon-whatsapp" />
                          </a>
                        </div>
                        <div className="icon">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={
                              user.device === 'desktop'
                                ? e =>
                                    this.gaEvents(
                                      e,
                                      'Social',
                                      'Facebook',
                                      'product share'
                                    )
                                : null
                            }
                            href={
                              user.device === 'desktop'
                                ? this.shareUrl('facebook')
                                : null
                            }
                          >
                            <span className="share-buton icon-fb" />
                          </a>
                        </div> */}
                        <div className="icon last">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={
                              user.device === 'desktop'
                                ? e =>
                                    this.gaEvents(
                                      e,
                                      'Social',
                                      'Twitter',
                                      'product share'
                                    )
                                : null
                            }
                            href={
                              user.device === 'desktop'
                                ? this.shareUrl('twitter')
                                : null
                            }
                          >
                            <span className="share-buton icon-twitter" />
                          </a>
                        </div>
                        <div className="label">
                          <span
                            className={`share-buton icon-share ${udfKeys.titleTextClassName}`}
                          />{' '}
                          {strings[lang].share}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <ReactTooltip />
            </>
          ) : (
            mobileFirstScreen()
          )}
        </div>
        {!isRblBank && topBanner({ classname: 'mrg-top-20 xs-mrg-top-10' })}
      </Fragment>
    );
  }
}

MetaCard.contextTypes = {
  router: PropTypes.object.isRequired
};

MetaCard.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  customerFeed: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  updateUserHandler: PropTypes.func,
  fetchOpsFeedHandler: PropTypes.func,
  fetchCustomerFeedHandler: PropTypes.func,
  handleImageLoad: PropTypes.func,
  fetchNprOpsFeedHandler: PropTypes.func,
  fetchNprCustomerFeedHandler: PropTypes.func,
  productsEnabled: PropTypes.bool,
  paymentInfo: PropTypes.any
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  rating: state.rating,
  user: state.user,
  page: state.page,
  customerFeed: state.customerFeed,
  paymentInfo: state.paymentInfo
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user)),
  fetchOpsFeedHandler: (trackingId, user) =>
    dispatch(opsFeedHandler(trackingId, user)),
  fetchCustomerFeedHandler: (tracking, customerInfo) =>
    dispatch(customerFeedHandler(tracking, customerInfo)),
  fetchNprOpsFeedHandler: (trackingId, security_key) =>
    dispatch(nprOpsFeedHandler(trackingId, security_key)),
  fetchNprCustomerFeedHandler: (tracking, security_key) =>
    dispatch(nprCustomerFeedHandler(tracking, security_key))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MetaCard)
);
