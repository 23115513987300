import Cookies from 'universal-cookie';
import toast from 'react-hot-toast';
import { get, post } from './utils/httpRequests';
import { APIs } from './utils/apis';
import { FILE_UPLOAD_KEY } from './utils/constants';
import {
  trackingInfo,
  trackingInfoMapping,
  rating,
  opsFeed,
  orderInfo as oInfo,
  orderInfoMapping,
  returnStatus,
  returnStatusMapping,
  storeCreditMapping,
  reverseRating
} from './utils/entity_mapping';
import { validateCodForm, validateRefund } from './utils/helper';
import { strings } from './utils/localizedStrings';
import {
  bankDetailNotRequiredForAdminDomains,
  exchangeTypes,
  LETSDRESSUP,
  steps
} from './components/exchange/defaultValues';
import { catalogueSteps } from './components/exchange/Catalogue';
import { statusMap } from './components/Payment';
// import { tssMenu as tss } from './utils/menu';

const cookies = new Cookies();

const trackingHandler = query => (dispatch, getState) => {
  const { theme } = getState().tracking;
  const { subdomain, lang } = getState().user;
  dispatch({
    type: 'TRACKING_REQUEST',
    tracking: Object.assign(trackingInfo, {
      isFetching: true,
      error: false,
      valid: false,
      theme
    })
  });
  return get({
    url:
      APIs.TRACKING +
      (query.orderId
        ? '&order_id=' + query.orderId
        : (query.cpId ? '&cp_id=' + query.cpId : '') +
          '&waybill=' +
          query.waybill) +
      (query.security_key ? '&security_key=' + query.security_key : '') +
      (theme.enterprise_user_id
        ? '&enterprise_id=' + theme.enterprise_user_id
        : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (!response.data.meta.success && response.data.meta.status === 400) {
        response.data.meta.message = strings[lang].noDataFound;
      }
      if (response.data.meta.success) {
        if (
          'shipments' in response.data.result &&
          response.data.result.shipments.length > 0
        ) {
          return response.data;
        }
        if (
          response.data.result?.config?.is_tech_service_provider &&
          theme.meta_data?.display_carrier_account_name
        ) {
          /**
           * @property response.data.result?.config?.is_tech_service_provider
           * If is_tech_service_provider flag is True, it means the carrier is a tech service provider that works as aggregator for shipping and logistics.
           * Otherwise, if it's False, the carrier is not a tech service provider, and it operates as a standard courier service.
           *
           * @property theme.meta_data?.display_carrier_account_name
           * If customer enabled this flag is True, it means the customer wants to display the aggregators carrier account name instead of the carrier name.
           * Otherwise, if it's False, it means the customer wants to display carrier name only.
           *
           * In case both flags are True, then display the account name as courier name instead.
           */

          response.data.result.cp_name = response.data.result.account_code;
        }
        // Trim the whitespace from the delivery rider's name if it exists.
        if (
          response.data.result[response.data.result.waybill].additional
            ?.deliveryRider?.name
        ) {
          response.data.result[
            response.data.result.waybill
          ].additional.deliveryRider.name = response.data.result[
            response.data.result.waybill
          ].additional.deliveryRider.name?.trim();
        }
        /**
         * Determine whether to display rider information based on certain conditions:
         * 1. The shipment is in out for delivery status(clickpost_status_code=6).
         * 2. Theme meta_data.display_rider_info is true.
         * 3. The delivery rider's phone number exists.
         */
        if (
          response.data.result[response.data.result.waybill].latest_status
            .clickpost_status_code === 6 &&
          theme.meta_data.display_rider_info &&
          response.data.result[response.data.result.waybill].additional
            ?.deliveryRider?.phoneNumber
        ) {
          theme.meta_data.display_rider_info = true;
        } else {
          theme.meta_data.display_rider_info = false;
        }
        /**
         * New config added for FE Details (Field Executive) - Starting with Nykaa
         * The Rider Config details will be deprecated and replaced with FE Details soon.
         * For now using in Nykaa theme (slick) only.
         */
        if (
          response.data.result[response.data.result.waybill].latest_status
            .clickpost_status_code === 6 &&
          theme.meta_data.display_rider_info &&
          response.data.result[response.data.result.waybill].additional
            ?.feDetails?.fePhoneNumber
        ) {
          theme.meta_data.display_fe_details = true;
        } else {
          theme.meta_data.display_fe_details = false;
        }
        if (
          response.data.result[response.data.result.waybill].additional
            ?.is_qc_enabled_carrier
        ) {
          theme.meta_data.showQCTheme = true;
        }
        /**
         * FE details config end
         */
        if (
          response.data?.result &&
          response.data?.result?.config &&
          response.data?.result?.config?.subdomain === subdomain
        ) {
          let tracking = trackingInfoMapping(
            query.waybill,
            response.data.result,
            lang,
            theme.meta_data
          );
          if (
            response.data.result[response.data.result.waybill].latest_status
              .clickpost_status_code === 23
          ) {
            dispatch({
              type: 'TRACKING_FAILURE',
              tracking: Object.assign(trackingInfo, {
                isFetching: false,
                error: true,
                message: strings[lang].noDataFound
              })
            });
            return {
              meta: {
                message: strings[lang].noDataFound,
                status: 400,
                success: false,
                isHome: true
              }
            };
          }
          dispatch({
            type: 'TRACKING_SUCCESS',
            tracking: Object.assign(tracking, {
              isFetching: false,
              error: false
            })
          });

          return response.data;
        } else {
          dispatch({
            type: 'TRACKING_FAILURE',
            tracking: Object.assign(trackingInfo, {
              isFetching: false,
              error: true,
              message: strings[lang].noDataFound
            })
          });
          return {
            meta: {
              message: strings[lang].noDataFound,
              status: 400,
              success: false,
              isHome: true
            }
          };
        }
      } else {
        if (
          !response.data.meta.success &&
          response.data.meta.status === 400 &&
          query.altUrl
        ) {
          trackingInfo.isFetching = true;
        } else {
          trackingInfo.isFetching = false;
        }
        dispatch({
          type: 'TRACKING_FAILURE',
          tracking: Object.assign(trackingInfo, {
            error: true,
            message: response.data.meta.message
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'TRACKING_FAILURE',
        tracking: Object.assign(trackingInfo, {
          isFetching: false,
          error: true
        })
      });
      return error;
    });
};

const eddHandler = query => (dispatch, getState) => {
  let { tracking, user } = getState();
  const { lang } = user;
  return get({
    url:
      APIs.EDD +
      (query.orderId
        ? '&order_id=' + query.orderId
        : (query.cpId ? '&cp_id=' + query.cpId : '') +
          '&waybill=' +
          query.waybill) +
      (query.security_key ? '&security_key=' + query.security_key : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.meta.success) {
        const predict = response.data.result.edd_ui;
        const predictEDD = response.data.result.edod;
        if (predict.message_id) {
          predict.message = strings[lang].edd(
            predict.message_id,
            predict.message
          );
        }
        tracking = Object.assign(tracking, { predict, predictEDD });
        dispatch({
          type: 'TRACKING_EDD_SUCCESS',
          tracking
        });
        return response.data;
      } else {
        dispatch({
          type: 'TRACKING_EDD_FAILURE',
          tracking
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'TRACKING_FAILURE',
        tracking: Object.assign(trackingInfo, { isFetching: true, error: true })
      });
      return error;
    });
};

const customerFeedHandler = (tracking, customer, security_key) => (
  dispatch,
  getState
) => {
  let { customerFeed } = getState();
  dispatch({
    type: 'CUSTOMER_FEED_REQUEST',
    customerFeed: Object.assign(customerFeed, {
      isFetching: true,
      error: false
    })
  });
  return get({
    url:
      APIs.FEED.GET +
      'tracking_id=' +
      tracking.trackingId +
      (security_key ? '&security_key=' + security_key : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      const customerFeed = response.data.result.feed;
      dispatch({
        type: 'CUSTOMER_FEED_SUCCESS',
        customerFeed: Object.assign(
          customerFeed,
          { isFetching: false, error: false },
          {
            address: response.data.result.feed.address,
            drop_pincode: response.data.result.feed.drop_pincode,
            landmark: response.data.result.feed.landmark,
            timestamp: response.data.result.feed.feedback_timestamp
          }
        )
      });
      tracking.hasRaisedIssue = customerFeed.issue_id ? true : false;
      if (response.data.result.new_ndr !== undefined) {
        tracking.isNewNdr = response.data.result.new_ndr;
      }
      dispatch({
        type: 'TRACKING_UPDATE',
        tracking: Object.assign(tracking, {
          isNdrFetched: true
        })
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'CUSTOMER_FEED_FAILURE',
        customerFeed: Object.assign(customerFeed, {
          isFetching: false,
          error: true
        })
      });
      return error;
    });
};

const opsFeedHandler = (trackingId, username, security_key) => (
  dispatch,
  getState
) => {
  const { lang } = getState().user;
  dispatch({
    type: 'OPS_FEED_REQUEST',
    opsFeed: Object.assign(opsFeed, { isFetching: true, error: false })
  });
  return get({
    url:
      APIs.OPS_FEED +
      'username=' +
      username +
      '&tracking_id=' +
      trackingId +
      (security_key ? '&security_key=' + security_key : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      let result =
        (response.data.result.feed && response.data.result.feed.issue_config) ||
        {};
      if (result.bucket_id) {
        result.raw_issue_desc = strings[lang].ndrBucket(result.bucket_id);
      }
      dispatch({
        type: 'OPS_FEED_SUCCESS',
        opsFeed: Object.assign(result, { isFetching: false, error: false })
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'OPS_FEED_FAILURE',
        opsFeed: Object.assign(opsFeed, { isFetching: false, error: true })
      });
      return error;
    });
};

const ratingHandler = trackingId => (dispatch, getState) => {
  const { user, tracking } = getState();
  const { nps_experience } = tracking.theme.config;
  dispatch({
    type: 'RATING_REQUEST',
    rating: Object.assign(rating, { isFetching: true, error: false })
  });
  return get({
    url:
      (nps_experience === 'BRAND_EXPERIENCE'
        ? APIs.BRAND_RATING
        : APIs.RATING.GET) +
      'tracking_id=' +
      trackingId +
      '&subdomain=' +
      user.subdomain
  })
    .then(response => {
      if (!response || response?.data?.meta?.status === 400) {
        throw Error(response.statusText);
      }
      let query = {};
      if (nps_experience === 'BRAND_EXPERIENCE') {
        query = {
          ...response.data,
          ratedBefore:
            typeof response.data.default_brand_experience === 'number',
          tracking_id: trackingId
        };
      } else {
        const { courier_rating, comment } = response.data.result;
        query = {
          star: courier_rating,
          comment,
          ratedBefore:
            courier_rating !== null && courier_rating !== undefined
              ? true
              : false
        };
      }
      dispatch({
        type: 'RATING_SUCCESS',
        rating: Object.assign(query, { isFetching: false, error: false })
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'RATING_FAILURE',
        rating: Object.assign(rating, { isFetching: false, error: true })
      });
      return error;
    });
};

const reverseRatingHandler = trackingId => (dispatch, getState) => {
  const { user, tracking } = getState();
  const { reverseNPS } = tracking.theme.config;
  dispatch({
    type: 'RATING_REQUEST_REVERSE',
    reverseRating: Object.assign(reverseRating, {
      isFetching: true,
      error: false
    })
  });
  return get({
    url:
      (reverseNPS === 'BRAND' ? APIs.BRAND_RATING : APIs.REVERSE_RATING) +
      'tracking_id=' +
      trackingId +
      '&subdomain=' +
      user.subdomain
  })
    .then(response => {
      if (!response || response?.data?.meta?.status === 400) {
        throw Error(response.statusText);
      }
      let query = {};
      if (reverseNPS === 'BRAND') {
        query = {
          ...response.data,
          ratedBefore:
            typeof response.data.default_brand_experience === 'number',
          tracking_id: trackingId
        };
      } else {
        const { courier_rating, comment } = response.data.result;
        query = {
          star: courier_rating,
          comment,
          ratedBefore:
            courier_rating !== null && courier_rating !== undefined
              ? true
              : false
        };
      }
      dispatch({
        type: 'RATING_SUCCESS_REVERSE',
        reverseRating: Object.assign(query, { isFetching: false, error: false })
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'RATING_FAILURE_REVERSE',
        reverseRating: Object.assign(reverseRating, {
          isFetching: false,
          error: true
        })
      });
      return error;
    });
};

const postConcernHandler = (trackingId, hasRaisedIssue, query) =>
  post({
    url:
      APIs.FEED.POST +
      'tracking_id=' +
      trackingId +
      (query.security_key ? '&security_key=' + query.security_key : ''),
    method: hasRaisedIssue ? 'PUT' : 'POST',
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });

const postPincodeHandler = query => dispatch => {
  dispatch({
    type: 'Pincode'
  });
  return post({
    url: APIs.PINCODE,
    method: 'POST',
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });
};

const postRatingHandler = query =>
  post({
    url: APIs.RATING.POST,
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });

const postReverseRatingHandler = query =>
  post({
    url: APIs.REVERSE_RATING,
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });

const postBrandRatingHandler = query =>
  post({
    url:
      APIs.BRAND_RATING +
      'subdomain=' +
      query.subdomain +
      '&security_key=' +
      query.security_key,
    data: query,
    headers: {
      'Content-Type': 'application/json'
    },
    method: query.ratedBefore ? 'PUT' : 'POST'
  }).then(response => {
    if (!response || response?.data?.meta?.status === 400) {
      throw Error(
        response?.data?.meta?.message ||
          'Something bad happened, please contact customer support'
      );
    }
    return response.data;
  });

const getShortlinkHandler = query =>
  post({
    url: APIs.RATING.SHORTLINK,
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });
// TEST: Disabling form submitting flow
// const postConcernHandler = () => {
//   const prom = new Promise(resolve => {
//   resolve(setTimeout(function(){}, 100));
//   });
//   return prom.then(() =>  {return {"meta":{"message":"Success","success":true,"status":200}}})
// }

// const postRatingHandler = () => {
//   const prom = new Promise(resolve => {
//   resolve(setTimeout(function(){}, 100));
//   });
//   return prom.then(() =>  {return {"meta":{"message":"Success","success":true,"status":200}}})
// }

const pageHandler = query => dispatch => {
  dispatch({
    type: 'PAGE_REQUEST',
    page: Object.assign(query, { isFetching: false })
  });
};

const updateUserHandler = user => dispatch => {
  dispatch({
    type: 'USER_UPDATE',
    user: Object.assign(user, { isFetching: false })
  });
};

const trackingResetHandler = () => (dispatch, getState) => {
  const { theme } = getState().tracking;
  dispatch({
    type: 'TRACKING_RESET',
    tracking: Object.assign(trackingInfo, {
      isFetching: true,
      error: false,
      theme
    })
  });
};

const googleSearchHandler = keyword => dispatch => {
  dispatch({
    type: 'GOOGLE_SEARCH_REQUEST',
    googleSearch: Object.assign({ list: [], isFetching: true, error: false })
  });
  return get({ url: APIs.GOOGLE_SEARCH + 'key=' + keyword })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.predictions.length > 0) {
        dispatch({
          type: 'GOOGLE_SEARCH_SUCCESS',
          googleSearch: Object.assign({
            list: response.data.predictions,
            isFetching: false,
            error: false
          })
        });
      }
      return response.predictions;
    })
    .catch(function(error) {
      dispatch({
        type: 'GOOGLE_SEARCH_FAILURE',
        googleSearch: Object.assign({
          list: [],
          isFetching: false,
          error: true
        })
      });
      return error;
    });
};

const resetGoogleSearchHandler = () => dispatch => {
  dispatch({
    type: 'GOOGLE_SEARCH_RESET',
    googleSearch: Object.assign({ list: [], isFetching: true, error: false })
  });
};

const orderInfoHandler = query => (dispatch, getState) => {
  const { meta_data } = getState().tracking.theme;
  dispatch({
    type: 'ORDER_INFO_REQUEST',
    data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
      isFetching: true,
      error: false,
      step: { current: 1, reached: 1 },
      action: {
        isReturnClk: false,
        activeIndex: -1,
        canGoToAddress: false,
        address: {
          select: -1,
          edit: -1,
          isEditting: false
        }
      },
      request: {}
    })
  });

  const orderIdKey =
    meta_data?.return_authorization_id === 5 ? 'order_id' : 'reference_number';

  const url =
    APIs.RETURN.NS_ORDER +
    'domain=' +
    query.domain +
    (query.awbs && query.key
      ? '&awbs=' + query.awbs
      : (query.key ? '&key=' + query.key : '') +
        (query.waybill ? '&waybill=' + query.waybill : '') +
        (query.mobile ? '&phone_number=' + query.mobile : '') +
        (query.email ? '&email=' + query.email : '') +
        (query.orderId ? `&${orderIdKey}=` + query.orderId : '') +
        (query.source ? '&source=' + query.source : '') +
        (query.token ? '&token=' + query.token : '') +
        (query.sku ? '&sku=' + query.sku : '') +
        (query.referenceNumber
          ? '&reference_number=' + query.referenceNumber
          : ''));

  return get({ url })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }

      if (response.data.meta.success) {
        let orderInfo = orderInfoMapping(response.data.result);
        let isErrorInCOD = false;
        if (
          meta_data &&
          meta_data.capture_bank_detail &&
          orderInfo.orderType === 'COD'
        ) {
          isErrorInCOD = true;
        }
        let confirmedRefundPayment = validateRefund(
          orderInfo.orderType,
          meta_data.capture_bank_detail,
          meta_data.store_credit_enabled
        );
        if (query.isReturnExchange) {
          confirmedRefundPayment = '';
        }
        dispatch({
          type: 'ORDER_INFO_SUCCESS',
          data: Object.assign(orderInfo, {
            isFetching: false,
            error: false,
            isErrorInCOD,
            confirmedRefundPayment
          })
        });
        return response.data;
      } else {
        dispatch({
          type: 'ORDER_INFO_FAILURE',
          data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
            isFetching: true,
            error: true,
            message: response.data.meta.message
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'ORDER_INFO_FAILURE',
        data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
          isFetching: true,
          error: true
        })
      });
      return error;
    });
};

const returnResonsHandler = domain => (dispatch, getState) => {
  dispatch({
    type: 'REASONS_REQUEST',
    data: Object.assign({
      isFetching: true,
      error: false
    })
  });
  dispatch({ type: 'RETURN_CONFIG_FETCHING' });

  const { page } = getState();

  return get({
    url:
      APIs.RETURN.NS_REASONS +
      'domain=' +
      domain +
      (page.source ? '&source=' + page.source : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }

      if (response.data.meta.success) {
        dispatch({
          type: 'REASONS_SUCCESS',
          data: Object.assign({
            isFetching: false,
            error: false,
            list:
              response.data.result.return_reasons &&
              response.data.result.return_reasons?.length > 0
                ? response.data.result.return_reasons
                    .filter(item => item.active || item.active === undefined)
                    .map(item => ({
                      value: item.id,
                      label: item.name,
                      imageRequired: item.imageRequired,
                      imageQuantity: item.imageQuantity,
                      subReasons: item?.sub_reasons?.map(subReason => ({
                        value: subReason.id,
                        label: subReason.name
                      }))
                    }))
                : []
          })
        });
        dispatch({
          type: 'UPDATE_CONFIG',
          data: Object.assign({
            reasonsWithSubReasons:
              response.data.data.returnReasonsWithSubReason &&
              response.data.data.returnReasonsWithSubReason?.length > 0
                ? response.data.data.returnReasonsWithSubReason.map(item => ({
                    value: item.id,
                    label: item.reason_name,
                    imageQuantity: item.imageQuantity,
                    exchangeOptions: item?.exchange_options,
                    subReasons: item?.sub_reasons?.map(subReason => ({
                      value: subReason.id,
                      label: subReason.name
                    }))
                  }))
                : [],
            instruction: response.data.data.exchangeInstructions,
            categoryBasedReason: response.data?.category_based_reason,
            catalogueConfig: {
              ...response.data.data.catalogue_config,
              percentageDiscount:
                response.data.data?.exchange_config
                  ?.require_percentage_discount ?? false
            },
            ...(response.data.data?.exchange_config ?? {})
          })
        });

        dispatch({
          type: 'RETURN_CONFIG_SUCCESS',
          data: response.data.data.refund_config
        });
        return response.data;
      } else {
        dispatch({
          type: 'REASONS_FAILURE',
          data: Object.assign({
            isFetching: true,
            error: true,
            message: response.data.meta.message,
            list: []
          })
        });
        dispatch({
          type: 'RETURN_CONFIG_SUCCESS',
          data: response.data?.data?.refund_config ?? {}
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'REASONS_FAILURE',
        data: Object.assign({ isFetching: true, error: true, list: [] })
      });
      return error;
    });
};

const returnClkEvent = () => (dispatch, getState) => {
  const data = getState().orderInfo;
  data.action.isReturnClk = true;
  dispatch({ type: 'RETURN_CLICKED_EVENT', data });
};

const cbClkEvent = index => (dispatch, getState) => {
  const data = getState().orderInfo;
  const metaData = getState().tracking.theme.meta_data;
  if (data.action.activeIndex === index) {
    data.action.activeIndex = -1;
  } else {
    data.action.activeIndex = index;
  }
  const product = data.products[index]; //&& !data.request[product.id].isSubmitted
  if (!data.request.hasOwnProperty(product.id)) {
    data.request[product.id] = {
      itemId: product.id,
      quantity: metaData.quantity_lock
        ? product.eligible_quantity_can_be_return
        : 1,
      price: product.price,
      store_credits_used: product.store_credits_used,
      final_amount_paid: product.final_amount_paid,
      collection_point_id: product.collection_point_id ?? null
    };
  }
  dispatch({ type: 'CHECKBOX_CLICKED_EVENT', data });
};
const cbClkEventId = id => (dispatch, getState) => {
  const data = getState().orderInfo;
  const metaData = getState().tracking.theme.meta_data;
  const product = data.products.find(product => product.id === id); //&& !data.request[product.id].isSubmitted
  if (!data.request.hasOwnProperty(product.id)) {
    data.request[product.id] = {
      itemId: product.id,
      quantity: metaData.quantity_lock
        ? product.eligible_quantity_can_be_return
        : 1,
      price: product.price,
      store_credits_used: product.store_credits_used,
      final_amount_paid: product.final_amount_paid,
      collection_point_id: product.collection_point_id ?? null
    };
  }
  dispatch({ type: 'CHECKBOX_CLICKED_EVENT', data });
};

export const directToExchangeSummary = query => (dispatch, getState) => {
  const {
    orderInfo,
    exchangeConfig: { reasonsWithSubReasons }
  } = getState();
  const selectedReasonId = reasonsWithSubReasons.filter(
    reason =>
      reason.label.toLowerCase().trim() ===
      query.exchangeReason.toLowerCase().trim()
  )?.[0]?.value;

  const selectedProduct = orderInfo.products[0];

  orderInfo.request[selectedProduct.id] = {
    type: 'exchange',
    description: selectedProduct.description,
    final_amount_paid: selectedProduct.final_amount_paid,
    images: selectedProduct.images,
    price: selectedProduct.price,
    sku: selectedProduct.sku,
    store_credits_used: selectedProduct.store_credits_used,
    id: selectedProduct.id,
    // variant_id: selectedProduct.iteminfoextended__variant_id,
    // thisProdVariant: selectedProduct.iteminfoextended__variant_id,
    quantity: 1,
    eligible_quantity_can_be_return:
      selectedProduct.eligible_quantity_can_be_return,
    eligible_quantity_can_be_exchange:
      selectedProduct.eligible_quantity_can_be_exchange,
    // variant_id: query.variantId ?? 32,
    selected_variant: query.newSize ?? '',
    exchangeSku: query.variantSku ?? '', // this is variant sku
    previous_variant: query.currSize ?? '',
    exchangePrice: selectedProduct.price, // this is variant price
    exchangeLogId: query.exchangeLogId ?? '',
    reasonId: {
      label: query.exchangeReason,
      value: selectedReasonId
    },
    exchangeType: 'SAME_PRODUCT_DIFFERENT_VARIANT'
  };
  orderInfo.step.exchangeCurrent = 7;
  orderInfo.action.address.select = 0;

  if (
    !selectedProduct.shipment_eligible_for_exchange ||
    selectedProduct.eligible_quantity_can_be_exchange === 0
  ) {
    orderInfo.error = true;
  }
  dispatch({
    type: 'ADD_PRODUCT',
    data: orderInfo
  });
};

const addProduct = request => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  orderInfo.action.activeIndex = -1;
  orderInfo.action.canGoToAddress = true;
  dispatch({
    type: 'ADD_PRODUCT',
    data: Object.assign(orderInfo, { request })
  });
};

const removeProduct = orderInfo => dispatch => {
  dispatch({
    type: 'REMOVE_PRODUCT',
    data: orderInfo
  });
};

const quantityChange = request => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  dispatch({
    type: 'ADD_PRODUCT',
    data: Object.assign(orderInfo, { request })
  });
};

const stepChange = step => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  dispatch({ type: 'STEP_EVENT', data: Object.assign(orderInfo, { step }) });
};

const actionChange = action => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  dispatch({
    type: 'ACTION_UPDATE',
    data: Object.assign(orderInfo, { action })
  });
};

const dateChange = date => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  dispatch({ type: 'DATE_EVENT', data: Object.assign(orderInfo, { date }) });
};

const addressChange = addresses => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  dispatch({
    type: 'ADDRESS_UPDATE',
    data: Object.assign(orderInfo, { addresses })
  });
};

const returnHandler = query => dispatch => {
  dispatch({
    type: 'RETURN_REQUEST',
    data: Object.assign({
      isFetching: true,
      error: false
    })
  });
  return post({
    url: APIs.RETURN.NS_REQUEST,
    method: 'POST',
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.meta.success) {
        dispatch({
          type: 'RETURN_SUCCESS',
          data: Object.assign({ isFetching: false, error: false })
        });
        return response.data;
      } else {
        dispatch({
          type: 'RETURN_FAILURE',
          data: Object.assign({
            isFetching: true,
            error: true,
            message: response.data.meta.message
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'RETURN_FAILURE',
        data: Object.assign({ isFetching: true, error: true, message: error })
      });
      return error;
    });
};

const onReturnLogin = query => (dispatch, getState) => {
  const { meta_data } = getState().tracking.theme;
  dispatch({
    type: 'RETURN_LOGIN_REQUEST',
    data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
      isFetching: true,
      error: false
    })
  });
  let data = { domain: query.domain, reference_number: query.orderId };
  if (query.email) {
    data.email = query.email;
  }
  if (query.mobile) {
    data.phone_number = query.mobile;
  }
  return post({
    url: APIs.RETURN.NS_ORDER,
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.meta.success) {
        let orderInfo = orderInfoMapping(response.data.result);
        let isErrorInCOD = false;
        if (
          meta_data &&
          meta_data.capture_bank_detail &&
          orderInfo.orderType === 'COD'
        ) {
          isErrorInCOD = true;
        }
        let confirmedRefundPayment = validateRefund(
          orderInfo.orderType,
          meta_data.capture_bank_detail,
          meta_data.store_credit_enabled
        );
        if (query.isReturnExchange) {
          confirmedRefundPayment = '';
        }
        dispatch({
          type: 'RETURN_LOGIN_SUCCESS',
          data: Object.assign(orderInfo, {
            isFetching: false,
            error: false,
            isErrorInCOD,
            confirmedRefundPayment,
            loggedUser: true,
            mobile: query.mobile,
            message: ''
          })
        });
        return response.data;
      } else {
        dispatch({
          type: 'RETURN_LOGIN_FAILURE',
          data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
            isFetching: true,
            error: true,
            message: response.data.meta.message
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'RETURN_LOGIN_FAILURE',
        data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
          isFetching: true,
          error: true
        })
      });
      return error;
    });
};

const returnStatusHandler = query => (dispatch, getState) => {
  const { lang, subdomain } = getState().user;
  dispatch({
    type: 'RETURN_STATUS_REQUEST',
    data: Object.assign(returnStatus, {
      isFetching: true,
      error: false
    })
  });
  return get({
    url:
      APIs.RETURN.NS_STATUS +
      `key=${query.key}&domain=${query.domain}&waybill=${query.waybill}&item_info_id=${query.itemInfoIds}&return_id=${query.returnIds}`
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.meta.success) {
        let returnStatusData = returnStatusMapping(
          response.data.result,
          lang,
          subdomain
        );
        dispatch({
          type: 'RETURN_STATUS_SUCCESS',
          data: Object.assign(returnStatusData, {
            isFetching: false,
            error: false
          })
        });
        return response.data;
      } else {
        dispatch({
          type: 'RETURN_STATUS_FAILURE',
          data: Object.assign(returnStatus, {
            isFetching: false,
            error: true,
            message: response.data.meta.message
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'RETURN_STATUS_FAILURE',
        data: Object.assign(returnStatus, { isFetching: true, error: true })
      });
      return error;
    });
};

const login = data => dispatch => {
  dispatch({ type: 'LOGIN_REQUEST' });
  return post({
    url: APIs.NS.LOGIN,
    data,
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.status === 200) {
        dispatch({
          type: 'LOGIN_SUCCESS',
          data: Object.assign({
            success: true,
            isFetching: false,
            error: false,
            entity: response.data
          })
        });
        if ('sub_token' in response.data) {
          // Saving data in cookie
          cookies.set('username', data.username, { path: '/' });
          for (const key in response.data) {
            cookies.set(key, response.data[key], { path: '/' });
          }
        }
      } else {
        dispatch({
          type: 'LOGIN_FAILURE',
          data: Object.assign({
            success: false,
            isFetching: false,
            error: true
          })
        });
      }
      return response;
    })
    .catch(function(error) {
      dispatch({
        type: 'LOGIN_FAILURE',
        data: Object.assign({
          success: false,
          isFetching: false,
          error: true,
          entity: error.response.data
        })
      });
      return error.response;
    });
};

const checkPincodeServiceability = pickupPincode => (dispatch, getState) => {
  const { orderInfo } = getState();
  let pincodeServiceability = orderInfo.pincodeServiceability;
  // dispatch({
  //   type: 'PINCODE_SERVICEABILITY_REQUEST',
  //   data: Object.assign(orderInfo)
  // });

  return get({
    url: `${APIs.RETURN.NS_PINCODE_SERVICEABILITY}shipment_uuid=${orderInfo.shipment_uuid}&pickup_pincode=${pickupPincode}`
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.data.meta.success) {
        const { pincode_serviceable, pincode_data } = response.data.result;
        const data = {
          isFetching: false,
          isServiceable: false,
          isChecked: true,
          pincodeData: {}
        };
        if (pincode_serviceable) {
          data.isServiceable = true;
        }
        if (pincode_data) {
          data.pincodeData = pincode_data;
        }
        pincodeServiceability = Object.assign(pincodeServiceability, data);
        dispatch({
          type: 'PINCODE_SERVICEABILITY_SUCCESS',
          data: Object.assign(orderInfo, pincodeServiceability)
        });
        return response.data;
      } else {
        pincodeServiceability = Object.assign(pincodeServiceability, {
          isFetching: false,
          error: true,
          message: response.data.meta.message
        });
        dispatch({
          type: 'PINCODE_SERVICEABILITY_FAILURE',
          data: Object.assign(orderInfo, {
            pincodeServiceability
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      pincodeServiceability = Object.assign(pincodeServiceability, {
        isFetching: false,
        error: true
      });
      dispatch({
        type: 'PINCODE_SERVICEABILITY_FAILURE',
        data: Object.assign(orderInfo, {
          pincodeServiceability
        })
      });
      return error;
    });
};

const addImage = query => (dispatch, getState) => {
  const { file } = getState();
  file.list.filter(item => item.name === query.name);
  file.list.push(query);
  dispatch({ type: 'ADD_IMAGE', data: file });
};

const removeImage = idx => (dispatch, getState) => {
  const { file } = getState();
  file.list.splice(idx, 1);
  dispatch({ type: 'REMOVE_IMAGE', data: file });
};
const newRemoveImage = (id, name) => (dispatch, getState) => {
  const { file } = getState();
  file.list = file.list
    .map(item => {
      if (item.name === name) {
        return { ...item, ids: item.ids.filter(i => i !== id) };
      } else {
        return item;
      }
    })
    .filter(item => item.ids.length > 0);
  dispatch({ type: 'REMOVE_IMAGE', data: file });
};

const setImages = list => (dispatch, getState) => {
  const { file } = getState();
  file.list = list;
  dispatch({ type: 'SET_IMAGE', data: file });
};

const resetImages = () => (dispatch, getState) => {
  let { file } = getState();
  file = { list: [] };
  dispatch({ type: 'RESET_IMAGE', data: file });
};

const uploadImageConfig = data => (dispatch, getState) => {
  const { file } = getState();
  file.list = [];
  file.imageQuantity = data.imageQuantity;
  file.imageRequired = data.imageRequired;
  dispatch({ type: 'UPLOAD_IMAGE_CONFIG', data: file });
};

const newUploadImageConfig = (data, id) => (dispatch, getState) => {
  const { file } = getState();
  file.list = file.list
    .map(item => ({ ...item, ids: item.ids.filter(i => i !== id) }))
    .filter(item => item.ids.length > 0);
  file.imageQuantity = data.imageQuantity;
  file.imageRequired = data.imageRequired;
  dispatch({ type: 'UPLOAD_IMAGE_CONFIG', data: file });
};

const preSignedUrl = idx => (dispatch, getState) => {
  const { file } = getState();
  const fileInfo = file.list[idx];
  return get({
    url:
      APIs.RETURN.PRESIGNED_URL +
      `file_key=${encodeURIComponent(fileInfo.preUrl)}&key=${FILE_UPLOAD_KEY}`
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      file.list[idx].url = response.data.result.url;
      file.list[idx].fields = response.data.result.fields;
      file.isUploading = true;
      dispatch({
        type: 'PRE_SIGNED_URL_IMAGE',
        data: file
      });
      return response.data;
    })
    .catch(function(error) {
      return error;
    });
};

const uploadFileToS3 = idx => (dispatch, getState) => {
  const { file } = getState();
  const fileInfo = file.list[idx];
  let data = new FormData();
  Object.keys(fileInfo.fields).map(key => {
    data.append(key, fileInfo.fields[key]);
  });
  data.append('file', fileInfo.rawFile);
  return post({ url: fileInfo.url, data })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.status === 204 && fileInfo?.fields?.key) {
        fileInfo.s3Url = fileInfo.url + fileInfo.fields.key;
        file.list[idx] = fileInfo;
        dispatch({
          type: 'PRE_SIGNED_URL_UPLOADED',
          data: file
        });
      }
      return response;
    })
    .catch(function(error) {
      return error;
    });
};

// File upload action creators for exchange flow adding exchange suffix for clarity
const addImageExchange = (query, prodId) => (dispatch, getState) => {
  const { file } = getState();
  file[prodId].list.filter(item => item.name === query.name);
  file[prodId].list.push(query);
  dispatch({ type: 'ADD_IMAGE', data: file });
};

const removeImageExchange = (idx, prodId) => (dispatch, getState) => {
  const { file } = getState();
  file[prodId].list.splice(idx, 1);
  dispatch({ type: 'REMOVE_IMAGE', data: file });
};

const resetImagesExchange = id => (dispatch, getState) => {
  let { file } = getState();
  delete file[id];
  dispatch({ type: 'RESET_PRODUCT_IMAGE', data: file });
};

const uploadImageConfigExchange = (data, prodId) => (dispatch, getState) => {
  const { file } = getState();
  file[prodId] = {};
  file[prodId].list = [];
  file[prodId].imageQuantity = data.imageQuantity;
  file[prodId].imageRequired = data.imageRequired;
  dispatch({ type: 'UPLOAD_IMAGE_CONFIG', data: file });
};

const preSignedUrlExchange = (idx, prodId) => (dispatch, getState) => {
  const { file } = getState();
  const fileInfo = file[prodId].list[idx];
  return get({
    url:
      APIs.RETURN.PRESIGNED_URL +
      `file_key=${encodeURIComponent(fileInfo.preUrl)}&key=${FILE_UPLOAD_KEY}`
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      file[prodId].list[idx].url = response.data.result.url;
      file[prodId].list[idx].fields = response.data.result.fields;
      file[prodId].isUploading = true;
      dispatch({
        type: 'PRE_SIGNED_URL_IMAGE',
        data: file
      });
      return response.data;
    })
    .catch(function(error) {
      return error;
    });
};

const uploadFileToS3Exchange = (idx, prodId) => (dispatch, getState) => {
  const { file } = getState();
  const fileInfo = file[prodId].list[idx];
  let data = new FormData();
  Object.keys(fileInfo.fields).map(key => {
    data.append(key, fileInfo.fields[key]);
  });
  data.append('file', fileInfo.rawFile);
  return post({ url: fileInfo.url, data })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.status === 204 && fileInfo?.fields?.key) {
        fileInfo.s3Url = fileInfo.url + fileInfo.fields.key;
        file[prodId].list[idx] = fileInfo;
        dispatch({
          type: 'PRE_SIGNED_URL_UPLOADED',
          data: file
        });
      }
      return response;
    })
    .catch(function(error) {
      return error;
    });
};

//end - File upload action creators for exchange flow adding exchange suffix for clarity

const fileUploadCompleted = () => (dispatch, getState) => {
  const { file } = getState();
  file.isUploading = false;
  dispatch({
    type: 'UPLOADED_IMAGE_FILE',
    data: file
  });
};

const updateBankDetail = bankDetail => (dispatch, getState) => {
  const { orderInfo, page, user } = getState();
  const isErrorInCOD =
    validateCodForm(bankDetail) &&
    !(
      bankDetailNotRequiredForAdminDomains.includes(user.subdomain) &&
      page.source.toLowerCase() === 'dashboard'
    );
  if (isErrorInCOD) {
    orderInfo.confirmedRefundPayment = 'source';
  } else {
    orderInfo.confirmedRefundPayment = 'BANK_ACCOUNT_TRANSFER';
  }
  dispatch({
    type: 'UPDATE_BANK_DETAIL',
    data: Object.assign(orderInfo, { bankDetail, isErrorInCOD })
  });
};

const updateLimitedBankDetail = bankDetail => (dispatch, getState) => {
  const { orderInfo, page, user } = getState();
  const isErrorInCOD =
    bankDetail.accountNumber &&
    bankDetail.accountNumber.length !== 24 &&
    bankDetail.bankName &&
    !(
      bankDetailNotRequiredForAdminDomains.includes(user.subdomain) &&
      page.source.toLowerCase() === 'dashboard'
    );
  if (!isErrorInCOD) {
    orderInfo.confirmedRefundPayment = 'source';
  }
  dispatch({
    type: 'UPDATE_BANK_DETAIL',
    data: Object.assign(orderInfo, { bankDetail, isErrorInCOD })
  });
};

const updateUPIDetails = upiDetails => (dispatch, getState) => {
  const { orderInfo } = getState();
  const isErrorInCOD = !upiDetails.upiName || !upiDetails.upiId;
  dispatch({
    type: 'UPDATE_UPI_DETAILS',
    data: Object.assign(orderInfo, { upiDetails, isErrorInCOD })
  });
};

const toggleBankFormValidation = isErrorInCOD => (dispatch, getState) => {
  const { orderInfo } = getState();
  dispatch({
    type: 'TOGGLE_BANK_FORM_VALIDATION',
    data: Object.assign(orderInfo, { isErrorInCOD })
  });
};

const selectRefundMethod = confirmedRefundPayment => (dispatch, getState) => {
  const { orderInfo } = getState();
  dispatch({
    type: 'SELECT_REFUND_METHOD',
    data: Object.assign(orderInfo, { confirmedRefundPayment })
  });
};

const resetBankDetail = bankDetail => (dispatch, getState) => {
  const { orderInfo } = getState();
  dispatch({
    type: 'RESET_BANK_DETAIL',
    data: Object.assign(orderInfo, { bankDetail })
  });
};

const resetUPIDetails = upiDetails => (dispatch, getState) => {
  const { orderInfo } = getState();
  dispatch({
    type: 'RESET_UPI_DETAILS',
    data: Object.assign(orderInfo, { upiDetails })
  });
};

const resetReturnView = () => dispatch => {
  dispatch({ type: 'RESET_RETURN' });
};

const fetchIfscDetail = ifsccode => () => {
  return get({ url: `${APIs.RETURN.IFSC}${ifsccode}` })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      return response.data;
    })
    .catch(function(error) {
      return error;
    });
};

const setStoreCreditInfo = () => (dispatch, getState) => {
  const { request, storeCredit } = getState().orderInfo;
  const data = storeCreditMapping(request);
  dispatch({
    type: 'SET_STORE_CREDIT_INFO',
    data: Object.assign(storeCredit, data)
  });
};

const updateStoreCreditInfo = query => (dispatch, getState) => {
  const { storeCredit } = getState().orderInfo;
  dispatch({
    type: 'UPDATE_STORE_CREDIT_INFO',
    data: Object.assign(storeCredit, query)
  });
};

const nprCustomerFeedHandler = (trackingId, security_key) => dispatch => {
  // let { customerFeed } = getState();
  dispatch({
    type: 'NPR_CUSTOMER_FEED_REQUEST'
  });
  return get({
    url:
      APIs.NPR.FEED +
      'tracking_id=' +
      trackingId +
      (security_key ? '&security_key=' + security_key : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      const customer = response.data.result.feed;
      dispatch({
        type: 'NPR_CUSTOMER_FEED_SUCCESS',
        customer: Object.assign(customer, {
          address: response.data.result.feed.address,
          drop_pincode: response.data.result.feed.drop_pincode,
          landmark: response.data.result.feed.landmark
        })
      });
      // tracking.hasRaisedIssue = customerFeed.issue_id ? true : false;
      // if(response.data.result.new_ndr !== undefined){
      //   tracking.isNewNdr = response.data.result.new_ndr;
      // }
      dispatch({
        type: 'REVERSE_TRACKING_UPDATE',
        data: {
          isReverseFetched: true
        }
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'NPR_CUSTOMER_FEED_FAILURE'
      });
      return error;
    });
};

const nprOpsFeedHandler = (trackingId, security_key) => (
  dispatch,
  getState
) => {
  const { lang } = getState().user;
  dispatch({
    type: 'NPR_OPS_FEED_REQUEST'
  });
  return get({
    url:
      APIs.NPR.OPS_FEED +
      'tracking_id=' +
      trackingId +
      (security_key ? '&security_key=' + security_key : '')
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      let result =
        (response.data.result.feed && response.data.result.feed.issue_config) ||
        {};
      if (
        response.data.result.feed.npr_bucket__npr_bucket__npr_bucket_code !==
        undefined
      ) {
        result.bucket_id =
          response.data.result.feed.npr_bucket__npr_bucket__npr_bucket_code;
      }
      if (result.bucket_id) {
        result.raw_issue_desc = strings[lang].nprBucket(result.bucket_id);
      }
      dispatch({
        type: 'NPR_OPS_FEED_SUCCESS',
        ops: result
      });
      return response.data;
    })
    .catch(function(error) {
      dispatch({
        type: 'NPR_OPS_FEED_FAILURE'
      });
      return error;
    });
};

const postNprConcernHandler = (trackingId, hasRaisedIssue, query) =>
  post({
    url:
      APIs.NPR.FEED +
      'tracking_id=' +
      trackingId +
      (query.security_key ? '&security_key=' + query.security_key : ''),
    method: hasRaisedIssue ? 'PUT' : 'POST',
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (!response) {
      throw Error(response.statusText);
    }
    return response.data;
  });

const fetchVariants = itemInfoId => async (dispatch, getState) => {
  const {
    user: { subdomain: domain },
    orderInfo
  } = getState();
  dispatch({ type: 'VARIANTS_FETCHING' });
  return await get({
    url:
      APIs.EXCHANGE.VARIANTS_CLIENT +
      new URLSearchParams({ domain, itemInfoId }).toString()
  })
    .then(res => {
      if (res?.data?.meta?.status === 200) {
        dispatch({
          type: 'ADD_VARIANTS',
          data: { list: res.data.result.dataList }
        });
        const prodIdx = orderInfo.products.findIndex(
          prod => prod.id === itemInfoId
        );

        const variantSameItem = res.data.result.dataList?.find(
          item =>
            item.id === Number(orderInfo.request[itemInfoId].thisProdVariant)
        );
        orderInfo.products[prodIdx].inventory_quantity = Math.max(
          variantSameItem?.inventory_quantity ?? 0,
          0
        );

        if (
          orderInfo.products[prodIdx].inventory_quantity > 0 ||
          (variantSameItem?.inventoryPolicy === 'CONTINUE' &&
            [LETSDRESSUP, '82e', 'harfun'].includes(domain))
        ) {
          orderInfo.products[prodIdx].exchangeTypesAvailable.push(
            exchangeTypes.SAME_PRODUCT_SAME_VARIANT
          );
        }

        orderInfo.products[prodIdx].inventoryPolicy =
          variantSameItem?.inventoryPolicy;

        orderInfo.products[prodIdx].total_variant_inventory_quantity =
          res.data.result.dataList?.reduce(
            (total, item) => total + Math.max(item.inventory_quantity ?? 0, 0),
            0
          ) ?? 0;

        const isDiffVariantExist =
          res?.data?.result?.dataList?.filter(
            item =>
              item?.id !==
              Number(orderInfo.request?.[itemInfoId]?.thisProdVariant)
          )?.length > 0;

        if (
          // later on can change this condition to account for inventoryPolicy as CONITNUE
          orderInfo.products[prodIdx].total_variant_inventory_quantity >
            orderInfo.products[prodIdx].inventory_quantity ||
          (isDiffVariantExist &&
            variantSameItem?.inventoryPolicy === 'CONTINUE' &&
            ['harfun', LETSDRESSUP].includes(domain))
        ) {
          orderInfo.products[prodIdx].exchangeTypesAvailable.push(
            exchangeTypes.SAME_PRODUCT_DIFFERENT_VARIANT
          );
        }

        orderInfo.products[prodIdx].isVariantsFetched = true;
        dispatch({ type: 'ADD_PRODUCT', data: orderInfo });
        return res.data.result;
      }
      if (res?.data?.meta?.status === 400) {
        dispatch({ type: 'VARIANTS_FETCHING_ERROR' });
      }
    })
    .catch(err => {
      return err;
    });
};

const fetchCatalogueListing = (query, listingType, selectedProd) => async (
  dispatch,
  getState
) => {
  const { user, ipAddress = {}, exchangeConfig } = getState();

  if (
    exchangeConfig?.catalogueConfig?.higher_price_item_only &&
    listingType === catalogueSteps.PRODUCTS
  ) {
    query.customer_ip = ipAddress.ip;
    query.item_price = selectedProd?.price;
  }

  dispatch({ type: 'VARIANTS_FETCHING' });
  return await get({
    url:
      APIs.EXCHANGE.CATALOGUE[
        listingType === catalogueSteps.CATEGORIES
          ? 'NS_CATEGORIES'
          : 'NS_PRODUCTS'
      ] +
      '?' +
      new URLSearchParams({
        ...query,
        domain: user.subdomain ?? ''
      }).toString()
  }).then(res => {
    if (res?.data?.meta?.status !== 200) {
      dispatch({
        type: 'VARIANTS_FETCHING_ERROR'
      });
      return;
    }

    dispatch({
      type: 'ADD_VARIANTS',
      data: {
        [listingType === catalogueSteps.CATEGORIES
          ? 'categories'
          : 'products']: res.data.result
      }
    });
    return res.data;
  });
};

const fetchCatalogueProduct = query => async (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: 'VARIANTS_FETCHING' });
  return await get({
    url:
      APIs.EXCHANGE.CATALOGUE.NS_PRODUCT_INFO +
      '?' +
      new URLSearchParams({
        ...query,
        domain: user.subdomain ?? ''
      }).toString()
  })
    .then(res => {
      if (res?.data?.meta?.status === 200) {
        dispatch({
          type: 'ADD_VARIANTS',
          data: {
            productDetails: res.data.result
          }
        });
      }
      return res.data;
    })
    .catch(error => {
      throw error;
    });
};

const resetVariants = () => dispatch => {
  dispatch({
    type: 'RESET_VARIANTS'
  });
};
// Done for BFL but can be expanded to other brands where pincode is not required but area names are mandatory
const getServiceableAreas = query => (dispatch, getState) => {
  const orderInfo = getState().orderInfo;
  const url =
    APIs.RETURN.SERVICEABLE_AREAS +
    'subdomain=' +
    query.subdomain +
    '&security_key=' +
    query.security_key +
    '&waybill=' +
    query.waybill;

  return get({ url })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      if (response.status === 200) {
        if (response.data?.meta?.status === 400) {
          dispatch({
            type: 'UPDATE_SERVICEABLE_AREAS',
            data: Object.assign(orderInfo, {
              isFetching: false,
              locationsFound: false,
              locationNotFoundMessage: response.data.meta.message
            })
          });
          return response.data;
        }
        dispatch({
          type: 'UPDATE_SERVICEABLE_AREAS',
          data: Object.assign({
            isFetching: false,
            locationsFound: true,
            locations: response.data
          })
        });
        return response.data;
      }
    })
    .catch(function(error) {
      dispatch({
        type: 'UPDATE_SERVICEABLE_AREAS',
        data: Object.assign(JSON.parse(JSON.stringify(oInfo)), {
          isFetching: false,
          error: true
        })
      });
      return error;
    });
};

// const pageRedirectionHandler = query =>
//   post({
//     url: APIs.PAGE_REDIRECTION,
//     method: 'POST',
//     data: query,
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   }).then(response => {
//     if (!response) {
//       throw Error(response.statusText);
//     }
//     return response.data;
//   });

// const pageRedirectionHandler = query =>
//   post({
//     url: APIs.PAGE_REDIRECTION,
//     method: 'POST',
//     data: query,
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   }).then(response => {
//     if (!response) {
//       throw Error(response.statusText);
//     }
//     return response.data;
//   });

const getPaymentInfo = () => (dispatch, getState) => {
  const { tracking, page, user } = getState();
  const searchParams = new URLSearchParams({
    shipment_id: tracking.trackingId,
    shipment_uuid: page.security_key,
    waybill: page.waybill,
    subdomain: user.subdomain
  });

  const url = APIs.PAYMENT_INFO.NS + searchParams.toString();
  dispatch({
    type: 'FETCH_PAYMENT_INFO'
  });
  return get({
    url
  })
    .then(res => {
      if (res?.data?.[0]?.meta?.status === 200) {
        if (
          res?.data?.[1]?.meta?.status === 400 &&
          res?.data?.[0]?.result?.status === statusMap.PAYMENT_LINK_GENERATED &&
          res?.data?.[0]?.result?.paymentLink
        ) {
          res.data[0].result.paymentLink = null;
        }

        dispatch({
          type: 'SET_PAYMENT_INFO',
          data: { isFetching: false, isError: false, ...res?.data?.[0]?.result }
        });
      } else {
        dispatch({ type: 'PAYMENT_INFO_FAILED' });
      }
      return res.data;
    })
    .catch(error => {
      dispatch({ type: 'PAYMENT_INFO_FAILED' });
      return error;
    });
};

const postPaymentInfo = query => (dispatch, getState) => {
  const { tracking } = getState();
  const searchParams = new URLSearchParams({
    shipment_id: tracking.trackingId
  });

  const url = APIs.PAYMENT_INFO.NS + searchParams.toString();
  dispatch({
    type: 'SUBMITTING_PAYMENT_INFO'
  });
  return post({
    url,
    data: query,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (res?.data?.meta?.status === 200) {
        dispatch({
          type: 'SUBMITTED_PAYMENT_INFO'
        });
      } else {
        dispatch({ type: 'PAYMENT_INFO_FAILED' });
      }
      return res.data;
    })
    .catch(error => {
      dispatch({ type: 'PAYMENT_INFO_FAILED' });
      return error;
    });
};

const exchangeNudgeHandler = (setState, goForward) => (dispatch, getState) => {
  const { orderInfo } = getState();
  const selectedProducts = Object.values(orderInfo.request).filter(
    p => p.shipment_eligible_for_exchange
  );

  orderInfo.request = {
    [selectedProducts?.[0].id]: {
      type: 'exchange',
      description: selectedProducts?.[0].description,
      final_amount_paid: selectedProducts?.[0].final_amount_paid,
      images: selectedProducts?.[0].images,
      price: selectedProducts?.[0].price,
      sku: selectedProducts?.[0].sku,
      store_credits_used: selectedProducts?.[0].store_credits_used,
      id: selectedProducts?.[0].id,
      variant_id: selectedProducts?.[0].iteminfoextended__variant_id,
      thisProdVariant: selectedProducts?.[0].iteminfoextended__variant_id,
      quantity: 1,
      eligible_quantity_can_be_return:
        selectedProducts?.[0].eligible_quantity_can_be_return,
      eligible_quantity_can_be_exchange:
        selectedProducts?.[0].eligible_quantity_can_be_exchange,
      shipment_eligible_for_exchange:
        selectedProducts?.[0].shipment_eligible_for_exchange,
      price_breakup: selectedProducts?.[0].price_breakup ?? {},
      discount_price: selectedProducts?.[0].discount_price ?? 0,
      tracking_info_id: selectedProducts?.[0].tracking_info_id
    }
  };
  setState(prev => ({ ...prev, editProductId: selectedProducts?.[0].id }));
  dispatch({ type: 'ADD_PRODUCT', data: orderInfo });
  return goForward(steps.REASON);
};

const updateExchangeInfo = data => dispatch => {
  dispatch({ type: 'UPDATE_EXCHANGE_INFO', data });
};

const ipAddressHandler = () => dispatch => {
  dispatch({ type: 'GET_IP_ADDRESS' });
  return get({
    url: APIs.ipify
  })
    .then(response => {
      if (!response) {
        throw Error(response.statusText);
      }
      dispatch({ type: 'SET_IP_ADDRESS', data: response.data.ip });
      return false;
    })
    .catch(function(error) {
      dispatch({ type: 'IP_ADDRESS_ERROR' });
      return error;
    });
};

const imageUploadHandler = async (
  imageFile,
  subdomain,
  addImage,
  getBase64,
  orderInfo,
  selectedProdId
) => {
  let { name, type } = imageFile;
  name = name.replace(/\s/g, '');

  if (type === 'image/heic') {
    const file = imageFile;
    const heic2any = (await import('heic2any')).default;
    let formData = new FormData();
    formData.append('file', file);
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg'
      });

      const rawFile = new File(
        [convertedBlob],
        file.name.replace(/\.heic$/i, '.jpeg'),
        {
          type: 'image/jpeg',
          lastModified: Date.now()
        }
      );
      let { name, type } = rawFile;
      name = name.replace(/\s/g, '');
      getBase64(rawFile, base64 => {
        var preUrl =
          'returns/' +
          subdomain +
          '/' +
          orderInfo.reference_number.replaceAll('#', '') +
          '/' +
          name;
        selectedProdId
          ? addImage({ name, base64, type, preUrl, rawFile }, selectedProdId)
          : addImage({ name, base64, type, preUrl, rawFile });
      });
    } catch (err) {
      console.error(err);
    }
  } else {
    let rawFile = imageFile;
    getBase64(rawFile, base64 => {
      var preUrl =
        'returns/' +
        subdomain +
        '/' +
        orderInfo.reference_number.replaceAll('#', '') +
        '/' +
        name;
      selectedProdId
        ? addImage({ name, base64, type, preUrl, rawFile }, selectedProdId)
        : addImage({ name, base64, type, preUrl, rawFile });
    });
  }
};

const surveyUrlGetter = (query, uniqueKey, surveyId) => {
  if (uniqueKey) {
    return get({
      url: `https://sandbox.kbill.in/api/v2/open-survey/customer-url?unique_key=${uniqueKey.value ??
        ''}&CustomerLastName=${query.CustomerLastName}&MobileNumber=${
        query.MobileNumber
      }&CustomerEmail=${query.CustomerEmail}&customerID=${
        query.customerID
      }&SurveyMedium=${query.SurveyMedium}&orderID=${
        query.orderId
      }&orderdate=null&ShipmentNo=${query.ShipmentNo}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res?.status === 200 && res?.data?.url) {
        return res.data.url;
      }
    });
  }

  if (surveyId) {
    return post({
      url: `https://ebill.abfrl.com/api/v2/open-survey/survey-url`,
      data: { ...query, survey_id: surveyId.value },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res?.status === 200 && res?.data?.url) {
        return res.data.url;
      }
    });
  }
};

const categoryBasedReasonsGetter = (productId, domain) => {
  if (productId) {
    return get({
      url: `${APIs.RETURN.NS_REASONS}product_id=${productId}&domain=${domain}`
    })
      .then(res => {
        if (
          res?.data?.meta?.status === 200 &&
          res?.data?.result?.return_reasons &&
          res?.data?.data?.returnReasonsWithSubReason
        ) {
          let exchangeReasons = res.data.data.returnReasonsWithSubReason.map(
            item => ({
              value: item.id,
              label: item.reason_name,
              imageQuantity: item.imageQuantity,
              exchangeOptions: item?.exchange_options,
              subReasons: item?.sub_reasons?.map(subReason => ({
                value: subReason.id,
                label: subReason.name
              }))
            })
          );
          let returnReasons = {
            isFetching: false,
            error: false,
            list: res.data.result.return_reasons
              .filter(item => item.active || item.active === undefined)
              .map(item => ({
                value: item.id,
                label: item.name,
                imageRequired: item.imageRequired,
                imageQuantity: item.imageQuantity,
                subReasons: item?.sub_reasons?.map(subReason => ({
                  value: subReason.id,
                  label: subReason.name
                }))
              }))
          };

          let data = {
            returnReasons: returnReasons,
            exchangeReasons: exchangeReasons
          };

          return data;
        }
      })
      .catch(err => toast.error(err));
  }
};

export {
  postConcernHandler,
  postPincodeHandler,
  postRatingHandler,
  postBrandRatingHandler,
  // fetchTssHeaderHandler,
  trackingHandler,
  ratingHandler,
  // tssHeaderHandler,
  opsFeedHandler,
  pageHandler,
  customerFeedHandler,
  updateUserHandler,
  trackingResetHandler,
  googleSearchHandler,
  resetGoogleSearchHandler,
  orderInfoHandler,
  returnClkEvent,
  cbClkEvent,
  cbClkEventId,
  addProduct,
  removeProduct,
  returnResonsHandler,
  quantityChange,
  stepChange,
  actionChange,
  dateChange,
  addressChange,
  returnHandler,
  onReturnLogin,
  returnStatusHandler,
  login,
  checkPincodeServiceability,
  eddHandler,
  addImage,
  removeImage,
  newRemoveImage,
  setImages,
  resetImages,
  uploadImageConfig,
  preSignedUrl,
  uploadFileToS3,
  addImageExchange,
  removeImageExchange,
  resetImagesExchange,
  uploadImageConfigExchange,
  newUploadImageConfig,
  preSignedUrlExchange,
  uploadFileToS3Exchange,
  updateBankDetail,
  updateUPIDetails,
  resetBankDetail,
  resetUPIDetails,
  resetReturnView,
  fetchIfscDetail,
  setStoreCreditInfo,
  updateStoreCreditInfo,
  toggleBankFormValidation,
  fileUploadCompleted,
  nprCustomerFeedHandler,
  nprOpsFeedHandler,
  postNprConcernHandler,
  selectRefundMethod,
  getShortlinkHandler,
  fetchVariants,
  fetchCatalogueListing,
  fetchCatalogueProduct,
  resetVariants,
  getServiceableAreas,
  // pageRedirectionHandler,
  getPaymentInfo,
  postPaymentInfo,
  exchangeNudgeHandler,
  updateLimitedBankDetail,
  updateExchangeInfo,
  reverseRatingHandler,
  postReverseRatingHandler,
  ipAddressHandler,
  imageUploadHandler,
  surveyUrlGetter,
  categoryBasedReasonsGetter
};
