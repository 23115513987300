import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../utils/localizedStrings';

const oneToFive = 'oneToFive';
const npsTen = 'oneToTen';
const npsNumbers = {
  mobile: { left: [0, 1, 2, 3, 4, 5], right: [6, 7, 8, 9, 10] },
  npsFive: [1, 2, 3, 4, 5],
  npsTen: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
};

const RatingFirstStep = ({
  onSubmit,
  state,
  onButtonClick,
  feedbackMessage,
  lang,
  isBrand = false,
  npsType,
  submitText
}) => {
  const getCategoryClassName = rating => {
    if (npsType === oneToFive) {
      switch (rating) {
        case 1:
        case 2:
          return 'detractor';

        case 3:
          return 'passive';
        case 4:
        case 5:
          return 'promoter';

        default:
          return null;
      }
    }

    if (isBrand) {
      switch (rating) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return 'detractor';
        case 7:
        case 8:
          return 'passive';
        // return 'passive-promoter';
        case 9:
        case 10:
          return 'promoter';

        default:
          return null;
      }
    }

    switch (rating) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 'detractor';

      case 6:
      case 7:
      case 8:
        return 'passive';

      case 9:
      case 10:
        return 'promoter';

      default:
        return null;
    }
  };

  const npsNumberType = () => {
    if (npsType === oneToFive) {
      return [...npsNumbers.npsFive];
    } else if (npsType === npsTen) {
      return [...npsNumbers.npsTen];
    } else {
      return [...npsNumbers.mobile.left, ...npsNumbers.mobile.right];
    }
  };

  const RatingButtons = ({ numbers }) =>
    numbers.map(npsNumber => (
      <button
        key={npsNumber}
        className={`circle-button ${
          state.rating < npsNumber || (isBrand && state.rating > npsNumber)
            ? ''
            : getCategoryClassName(state.rating)
        } ${state.rating === npsNumber ? 'current' : ''}`}
        onClick={() => onButtonClick(npsNumber)}
      >
        {npsNumber}
      </button>
    ));
  return (
    <>
      {feedbackMessage ? (
        <p className="li-ht-24 fs-18 md-fs-20 fw-600">{feedbackMessage}</p>
      ) : null}
      {/* // <p className="li-ht-24 fs-20 fw-600">{feedbackMessage}</p> */}
      <div className="button-container">
        {/* <div className="left hidden visible-xs-flex">
          <RatingButtons
            numbers={
              npsType === oneToFive
                ? npsNumbers.npsFive
                : npsNumbers.mobile.left
            }
          />
        </div> */}
        {/* <div
          className={`right ${
            npsType === oneToFive ? 'hidden' : 'hidden visible-xs-flex'
          } `}
        >
          <RatingButtons numbers={npsNumbers.mobile.right} />
          <button className="circle-button extra">11</button>
        </div> */}
        <div className={`${npsType === oneToFive ? 'md-w-50' : ''} all-rating`}>
          <RatingButtons numbers={npsNumberType()} />
        </div>
        <div
          className={`${
            npsType === oneToFive ? 'md-w-50' : ''
          } mrg-top-5 mrg-btm-20 hidden-xs all-rating`}
        >
          <span className="negative-text">
            {strings[lang][isBrand ? 'veryUnlikely' : 'veryPoor']}
          </span>
          <span className="positive-text">
            {strings[lang][isBrand ? 'veryLikely' : 'excellent']}
          </span>
        </div>
      </div>
      {onSubmit ? (
        <div className="mrg-top-10">
          <button
            className={`submit button rating`}
            onClick={onSubmit}
            disabled={
              (isBrand && state.rating === null) || (!isBrand && !state.rating)
            }
          >
            {submitText ?? strings[lang].next}
          </button>
        </div>
      ) : null}
      <p className="mrg-top-15 text-gray visible-xs">
        {{
          [oneToFive]: 'Note: 1 denotes very poor & 5 denotes excellent',
          oneToTen: 'Note: 1 denotes very poor & 10 denotes excellent'
        }[npsType] ?? 'Note: 0 denotes very poor & 10 denotes excellent'}
      </p>
    </>
  );
};

RatingFirstStep.propTypes = {
  onSubmit: PropTypes.any,
  state: PropTypes.object,
  onButtonClick: PropTypes.any,
  feedbackMessage: PropTypes.string,
  lang: PropTypes.string,
  isBrand: PropTypes.bool,
  npsType: PropTypes.any,
  submitText: PropTypes.string
};

export default RatingFirstStep;
